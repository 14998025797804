import { createApp } from 'vue';
import App from './App.vue';
import httpRequest from '@/utils/httpRequest';
import router from './router';
import ElementPlus from 'element-plus';  // 引入 Element Plus
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


const app = createApp(App);
// 使用 Element Plus 插件
app.use(ElementPlus);
// 将 httpRequest 注册为全局属性
app.config.globalProperties.$httpRequest = httpRequest;
console.log("1",app.config.globalProperties.$http);
app.use(router).mount('#app');
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
