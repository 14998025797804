<template>
  <div class="hello">
    <h1>{{ msg }}</h1>

    <div class="mt-4">
      <el-input
          v-model="input3"
          style="max-width: 600px"
          placeholder="输入关键字搜索"
          class="input-with-select"
      >
        <template #append>
          <el-button :icon="Search" @click="search" />
        </template>
      </el-input>
    </div>

    <h3>全网短剧实时更新</h3>
    <h6>由于页面显示有限，仅展示最新的100条内容，获取其他资源请在搜索框搜索。</h6>
    <ul>
      <li v-for="(item, index) in items" :key="index">
        <div class="item-content">
          <span class="name">{{ item.name }}</span>
          <span class="content">
            <a :href="item.content" target="_blank" rel="noopener">{{ item.content }}</a>
          </span>
        </div>
      </li>
    </ul>
  </div>
  <!-- 页脚 -->
  <footer class="footer">
    <p>声明：本站是网盘资源分享平台,所有内容均来源于网络资源，对其内容不负任何责任，若有问题，请联系我们。</p>
    <p>QQ：1158622356</p>
    <p>&copy; 2024 夸克网盘 | 短剧资源分享平台</p>
<!--    <p>ICP备案号: 12345678号</p> &lt;!&ndash; 在这里添加备案号 &ndash;&gt;-->
  </footer>

</template>

<script>
import { Search } from '@element-plus/icons-vue'
export default {
  name: 'HelloWorld',
  computed: {
    Search() {
      return Search
    }
  },
  props: {
    msg: String
  },

  data() {
    return {
      input3: '',

      items: []
    };
  },
  created() {
    this.fetchData();
  },

  methods: {
    async search() {
      if (!this.input3) {
        this.$message.error('请输入搜索关键词');
        return;
      }

      try {
        // 调用后端接口
        //const response = await this.$httpRequest.post('http://localhost:10521/web/search', {
        const response = await this.$httpRequest.post('https://tool2.yuruistar.com/web/search', {
          keyword: this.input3
        });
        console.log('response:', response);
        // 假设后端返回的数据是一个列表，赋值给 items
        this.items = response.data;
        console.log('搜索结果:', this.items);
      } catch (error) {
        console.error('请求失败:', error);
        this.$message.error('搜索失败，请稍后再试');
      }
    },
    async fetchData() {
      try {
        console.log("开始");
        //const response = await this.$httpRequest.post('http://localhost:10521/web/queryName', { /* 你的数据 */ });
        const response = await this.$httpRequest.post('https://tool2.yuruistar.com/web/queryName', { /* 你的数据 */ });
        console.log("成功", response.data);
        this.items = response.data;  // 假设返回的数据格式中有一个 'data' 字段
      } catch (error) {
        console.error("请求失败", error);
      }
    }
  }

}
</script>


<style scoped>
.input-with-select .el-input-group__prepend {
  background-color: var(--el-fill-color-blank);
}
/* 页面的外层容器 */
.hello {
  width: 80%; /* 默认宽度占屏幕的80% */
  margin: 0 auto; /* 居中 */
  padding: 20px;
  box-sizing: border-box;
}

/* 屏幕宽度小于768px时调整为100%宽度 */
@media (max-width: 768px) {
  .hello {
    width: 100%; /* 在小屏幕上占满整个屏幕 */
    padding: 10px;
  }

  ul {
    grid-template-columns: 1fr; /* 在小屏幕上变为1列 */
  }
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr; /* 默认两列布局 */
  gap: 10px; /* 列之间的间距 */
}

li {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc; /* 每个条目的边框 */
  padding: 10px;
  border-radius: 4px;
}

.item-content {
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: bold;
  margin-bottom: 5px;
}

.content a {
  color: #42b983;
  text-decoration: none;
}

.content a:hover {
  text-decoration: underline;
}
</style>
